import React, { Component } from 'react';
import AuthService from './AuthService';
import  { Navigate } from 'react-router-dom';
import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.authenticated = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.Auth = new AuthService();
  }

  componentDidMount() {
  }

  render() {
    if(this.Auth.loggedIn()) {
      return (
        <Navigate to='/' />
      );
    }
    return (
      <div className="center">
        <div className="card">
          <h1>Login</h1>
          <form onSubmit={this.handleSubmit}>
            <input
              className="form-item"
              placeholder="Username goes here..."
              name="username"
              type="text"
              onChange={this.handleChange}
            />
            <input
              className="form-item"
              placeholder="Password goes here..."
              name="password"
              type="password"
              onChange={this.handleChange}
            />
            <input className="form-submit" value="SUBMIT" type="submit" />
          </form>
        </div>
      </div>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.Auth.login(this.state.username,this.state.password)
      .then(res => {
        if(res === false) {
          alert("No such user or incorrect password");
        } else {
          this.setState({ authenticated: true });
        }
      })
      .catch(err => {
          alert(err);
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

}

export default Login;
